import { CopyFilled } from "@ant-design/icons";
import { DataType, DtoFieldMetadata, DtoMetadata } from "@clairejs/core";
import { Button, Popover, Tooltip, Tree } from "antd";
import { useState } from "react";
import styled, { CSSProperties } from "styled-components";

import { copyToClipBoard } from "../utils";

interface Props {
    dto: DtoFieldMetadata;
    showCopy?: boolean;
}

interface JsonTree {
    item?: any;
    leftItem?: any;
    rightItem?: any;
    isArray?: boolean;
    primitiveChildren?: boolean;
    children?: JsonTree[];
}

const convertDtoField = (field: DtoFieldMetadata, level: number = 0): JsonTree => {
    return {
        leftItem: field.name && (
            <Tooltip placement="right" trigger={["click"]} title={field.description}>
                <Button size="small" type="link">
                    {field.name + (!field.isRequired ? " (optional)" : "")}
                </Button>
            </Tooltip>
        ),
        isArray: !!field.vectorProps,
        rightItem:
            field.elementDto || field.vectorProps
                ? null
                : field.dataType +
                  (field.regex ? ` (regex: ${field.regex})` : "") +
                  (field.enum ? ` (${field.enum.map((v) => JSON.stringify(v)).join(" | ")})` : ""),

        primitiveChildren:
            field.vectorProps?.elementDataType &&
            ![DataType.ARRAY, DataType.OBJECT].includes(field.vectorProps.elementDataType),

        children: field.elementDto
            ? field.elementDto.fields.map((f) => convertDtoField(f, level + 1))
            : field.vectorProps
            ? [
                  {
                      item:
                          field.vectorProps?.elementDataType +
                          (field.vectorProps.superSet
                              ? ` (${field.vectorProps.superSet.map((v) => JSON.stringify(v)).join(" | ")})`
                              : ""),
                  },
              ]
            : undefined,
    };
};

const getTreeData: any = (parentKey: string, tree: JsonTree, expanded: string[]) => {
    const openKey = `${parentKey}/{`;
    const closeKey = `${parentKey}/}`;
    const leftBracket = tree.isArray ? (tree.primitiveChildren ? "[" : "[{") : "{";
    const rightBracket = tree.isArray ? (tree.primitiveChildren ? "]" : "}]") : "}";

    const style: CSSProperties = {
        display: "flex",
        whiteSpace: "pre",
        alignItems: "center",
        userSelect: "text",
    };

    if (!tree.children) {
        return tree.item
            ? [
                  {
                      title: <div style={style}>{tree.item}</div>,
                      key: openKey,
                  },
              ]
            : [
                  {
                      title: (
                          <div style={style}>
                              {tree.leftItem && (
                                  <>
                                      <div>{tree.leftItem}</div>
                                      <div>{" : "}</div>
                                  </>
                              )}
                              <div>{tree.rightItem}</div>
                          </div>
                      ),
                      key: openKey,
                  },
              ];
    }

    return !expanded.includes(openKey)
        ? [
              {
                  title: (
                      <div style={style}>
                          {tree.leftItem && (
                              <>
                                  <div>{tree.leftItem}</div>
                                  <div>{" : "}</div>
                              </>
                          )}
                          <div>{`${leftBracket}...${rightBracket}`}</div>
                      </div>
                  ),
                  key: openKey,
                  //-- just a dummy child here to trigger expansion
                  children: [{ title: "...", key: `${openKey}/...` }],
              },
          ]
        : [
              {
                  title: (
                      <div style={style}>
                          {tree.leftItem && (
                              <>
                                  <div>{tree.leftItem}</div>
                                  <div>{" : "}</div>
                              </>
                          )}
                          <div>{`${leftBracket}`}</div>
                      </div>
                  ),
                  key: openKey,
                  children: tree.children
                      .map((subtree, index) => getTreeData(`${openKey}/${index}`, subtree, expanded))
                      .reduce((collector, nodes) => collector.concat(...nodes), [] as any),
              },
              { title: `${rightBracket}`, key: closeKey },
          ];
};

const Wrapper = styled.div`
    .tools {
        display: flex;
        justify-content: flex-end;
    }
    .tree {
        font-family: monospace;
    }
`;

const rep = (text: string, count: number) => {
    let result = "";
    for (let i = 0; i < count; i++) {
        result += text;
    }
    return result;
};

const getField = (field: DtoFieldMetadata, level: number = 0) => {
    const tabs = rep("\t", level);
    let value = "";
    if (field.dataType === DataType.STRING) {
        value = `""`;
    } else if (field.dataType === DataType.NUMBER) {
        value = `0`;
    } else if (field.dataType === DataType.BOOLEAN) {
        value = `false`;
    } else if (field.vectorProps) {
        if (field.vectorProps.elementDataType === DataType.STRING) {
            value = `[\n${rep("\t", level + 1)}""\n${tabs}]`;
        } else if (field.vectorProps.elementDataType === DataType.NUMBER) {
            value = `[0]`;
        } else if (field.vectorProps.elementDataType === DataType.BOOLEAN) {
            value = `[true]`;
        } else {
            if (!field.elementDto) {
                value = ``;
            } else {
                value = `[\n${getDto(field.elementDto, level + 1)}\n${tabs}]`;
            }
        }
    } else if (field.elementDto) {
        value = `{${field.elementDto.fields.map((f) => getField(f, level + 1))}\n${tabs}}`;
    }

    return `\n${tabs}"${field.name}": ${value}`;
};

const getDto = (dto: DtoMetadata, level = 0) => {
    const tabs = rep("\t", level);
    return `${tabs}{` + dto.fields.map((f) => getField(f, level + 1)) + `\n${tabs}}`;
};

const getJson = (dto: DtoFieldMetadata, level: number = 0) => {
    if (dto.dataType === DataType.STRING) {
        return `""`;
    } else if (dto.dataType === DataType.NUMBER) {
        return `0`;
    } else if (dto.dataType === DataType.BOOLEAN) {
        return `false`;
    } else {
        if (!dto.elementDto) {
            return "";
        }
        const tabs = rep("\t", level);
        return `${tabs}{` + dto.elementDto.fields.map((f) => getField(f, level + 1)) + `\n${tabs}}`;
    }
};

export default function DtoFieldJson({ dto, showCopy }: Props) {
    const [expanded, setExpanded] = useState<string[]>([]);
    const [copied, setCopied] = useState(false);

    const copy = () => {
        copyToClipBoard(getJson(dto));
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Wrapper>
            {showCopy && (
                <div className="tools">
                    <Popover content="Copied!" visible={copied}>
                        <Button size="small" icon={<CopyFilled />} onClick={copy}>
                            Copy
                        </Button>
                    </Popover>
                </div>
            )}
            {dto.dataType !== DataType.OBJECT && dto.dataType !== DataType.ARRAY ? (
                <div>{dto.dataType}</div>
            ) : (
                <Tree
                    className="tree"
                    selectable={false}
                    showLine={
                        ![DataType.ARRAY, DataType.OBJECT].includes(dto.dataType) ||
                        (dto.dataType === DataType.ARRAY && dto.vectorProps?.elementDataType !== DataType.OBJECT)
                            ? false
                            : { showLeafIcon: false }
                    }
                    expandedKeys={expanded}
                    onExpand={(_, { expanded, node }) => {
                        if (expanded) {
                            setExpanded((co) => co.concat(node.key as string));
                        } else {
                            setExpanded((co) => co.filter((c) => c !== node.key));
                        }
                    }}
                    treeData={getTreeData("0", convertDtoField(dto), expanded)}
                />
            )}
        </Wrapper>
    );
};
