import { useNavigator } from "@clairejs/react";

import { ApiDisplayInfo } from "./dto/queries";

export const useApiNavigator = () => {
    const navigator = useNavigator();
    const queries = navigator.getQueries();

    const navigateAPI = (info: ApiDisplayInfo) => {
        navigator.navigate({
            search: `?apiInfo=${encodeURIComponent(JSON.stringify(info))}`,
        });
    };

    const getApiQuery = (apiInfo: ApiDisplayInfo) => {
        return `?apiInfo=${encodeURIComponent(JSON.stringify(apiInfo))}`;
    };

    const getApiObject = () => {
        const apiInfo: ApiDisplayInfo = queries.apiInfo ? JSON.parse(decodeURIComponent(queries.apiInfo)) : {};
        return apiInfo;
    };

    return { navigator, navigateAPI, getApiQuery, getApiObject };
};
