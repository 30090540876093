import { Injectable } from "@clairejs/core";
import { CURRENT_LANGUAGE, SYSTEM_LANGUAGES } from "../constants";
import { AbstractStorage } from "../system/AbstractStorage";
import { TranslationMap } from "./types";

const keyResolver = (key: string) => `__${key}__`;
type LangSubscriber = (lang: string) => void;

@Injectable()
export class Translator {
    currentLang?: string = null!;
    subscribers: LangSubscriber[] = [];

    constructor(readonly storage: AbstractStorage) {}

    addLangSubscriber(subscriber: LangSubscriber) {
        this.subscribers.push(subscriber);
    }

    removeLangSubscriber(subscriber: LangSubscriber) {
        this.subscribers = this.subscribers.filter((s) => s !== subscriber);
    }

    async getCurrentLanguage() {
        if (this.currentLang === null) {
            this.currentLang = ((await this.storage.getItem(CURRENT_LANGUAGE)) as any as string) || undefined;
        }
        return this.currentLang;
    }

    async setCurrentLanguage(lang: string) {
        this.currentLang = lang;
        await this.storage.setItem(CURRENT_LANGUAGE, lang);
        for (const sub of this.subscribers) {
            sub(lang);
        }
    }

    async getLanguages() {
        return ((await this.storage.getItem(SYSTEM_LANGUAGES)) as any as string[]) || undefined;
    }

    async setLanguages(languages: string[]) {
        await this.storage.setItem(SYSTEM_LANGUAGES, languages);
    }

    getTranslationObject(translationMap: TranslationMap) {
        const _this = this;
        const locales = Object.keys(translationMap);
        const translation: any = {};

        const resolveTranslation = (translationsObject: any, translationValues: any[]): void => {
            //-- get all keys of languages
            const keys = translationValues.reduce((collector, t) => {
                Object.keys(t).forEach((key) => {
                    if (!collector.includes(key)) {
                        collector.push(key);
                    }
                });
                return collector;
            }, [] as string[]);

            //-- create getters / setters for those keys
            for (const key of keys) {
                const isObject = translationValues.every((value) => typeof value[key] === "object");
                if (isObject) {
                    translationsObject[key] = {};
                    resolveTranslation(
                        translationsObject[key],
                        translationValues.map((value) => value[key])
                    );
                } else {
                    const tKey = keyResolver(key);
                    translationsObject[tKey] = translationValues.map((value) => value[key]);
                    Object.defineProperty(translationsObject, key, {
                        value: function (...args: any[]): string {
                            const currentLocale = _this.currentLang || locales[0];
                            if (!currentLocale) {
                                return "";
                            }

                            let translation = this[tKey][locales.indexOf(currentLocale)];
                            if (typeof translation === "string") {
                                return translation;
                            } else if (typeof translation === "function") {
                                return translation(...args);
                            } else {
                                return tKey;
                            }
                        },
                        configurable: false,
                        writable: false,
                    });
                }
            }
        };

        resolveTranslation(translation, Object.values(translationMap));
        return translation;
    }
}
