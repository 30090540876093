import { SocketPlainMessage } from "@clairejs/core";
import { Subscriber, Observable } from "rxjs";

import { IClientSocket } from "./IClientSocket";

export class ClientSocket implements IClientSocket {
    reconnectHandler?: () => void;
    disconnectHandler?: () => void;
    messageHandler?: (message: SocketPlainMessage) => void;
    messsageSendingSubscriber?: Subscriber<SocketPlainMessage>;

    public constructor(
        private readonly messageReceivingObservable: Observable<SocketPlainMessage>,
        private readonly connectionObservable: Observable<boolean>,
        private readonly messsageSendingSubscriberResolver: (socket: IClientSocket) => Subscriber<SocketPlainMessage>,
        private readonly initFn: (socket: IClientSocket) => void,
        private readonly destructorFn: (socket: IClientSocket) => void
    ) {}

    send(message: SocketPlainMessage): void {
        if (!this.messsageSendingSubscriber) {
            this.messsageSendingSubscriber = this.messsageSendingSubscriberResolver(this);
        }
        this.messsageSendingSubscriber.next(message);
    }

    onMessage(handler: (message: SocketPlainMessage) => void): void {
        this.messageHandler = handler;
    }

    onDisconnect(handler: () => void): void {
        this.disconnectHandler = handler;
    }

    onReconnect(handler: () => void): void {
        this.reconnectHandler = handler;
    }

    connect(): void {
        this.connectionObservable.subscribe({
            next: (connected) => {
                if (connected) {
                    this.reconnectHandler && this.reconnectHandler();
                } else {
                    this.disconnectHandler && this.disconnectHandler();
                }
            },
        });
        this.messageReceivingObservable.subscribe({ next: (message) => this.messageHandler && this.messageHandler(message) });
        this.initFn(this);
    }

    disconnect(): void {
        this.destructorFn(this);
    }
}
