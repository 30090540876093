import { Injectable, AccessToken } from "@clairejs/core";
import { AbstractStorage } from "../system/AbstractStorage";
import { AbstractTokenManager } from "./AbstractTokenManager";

const ACCESS_TOKEN = "ACCESS_TOKEN";

@Injectable()
export class DefaultTokenManager extends AbstractTokenManager {
    private accessToken?: AccessToken = null!;

    constructor(readonly storage: AbstractStorage) {
        super();
    }

    async getAccessToken(): Promise<AccessToken | undefined> {
        if (this.accessToken === null) {
            this.accessToken = await this.storage.getItem(ACCESS_TOKEN);
        }
        return this.accessToken;
    }

    async setAccessToken(token?: AccessToken): Promise<void> {
        if (token) {
            await this.storage.setItem(ACCESS_TOKEN, token);
        } else {
            await this.storage.deleteItem(ACCESS_TOKEN);
        }
        this.accessToken = token;
    }
}
