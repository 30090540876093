import { ApiInfo } from "@clairejs/core";
import { useDebounce } from "@clairejs/react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Affix, Button, Divider, Input, Modal } from "antd";
import { EditFilled, SearchOutlined, SendOutlined } from "@ant-design/icons";

import { useApiNavigator } from "./hooks";
import PermissionItem from "./permissions/PermisisonItem";

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    .fix-top {
        & > div {
            width: 100% !important;
        }
        .ant-affix {
            width: 100% !important;
        }
    }

    .page-header {
        padding: 8pt 21pt;
        display: flex;
        justify-content: space-between;
        background: linear-gradient(180deg, rgba(4, 152, 194, 1) 0%, rgba(90, 202, 235, 0.5) 100%);
        .search-input,
        .url-input {
            max-width: 233pt;
        }

        .url-input {
            margin-right: 8pt;
        }

        .search-input {
            margin-left: 8pt;
        }

        .api-url {
            color: #eee;
            font-size: 13pt;
            margin-right: 8pt;
        }
    }

    .main-content {
        padding: 21pt;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .message {
            font-size: 1.2rem;
            color: #aaaaaa;
        }

        .permissions {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;

            .filter {
                padding: 13pt 0;
                text-align: center;
            }

            .ant-card {
                margin-bottom: 13pt;
            }

            .permission-group {
            }
        }
    }
`;

export default function App() {
    const { navigateAPI, getApiObject } = useApiNavigator();

    const [apiUrl, setApiUrl] = useState("");
    const [id, setId] = useState("");
    const [filter, setFilter] = useState("");

    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(false);
    const [permissions, setPermissions] = useState<ApiInfo[]>();

    const debouncedFilter = useDebounce(setFilter, 200);

    const search = async (url: string) => {
        if (apiUrl === url) {
            getApiInfo(url);
        } else {
            navigateAPI({
                apiUrl: url,
            });
        }
    };

    const getApiInfo = async (mount: string) => {
        if (!mount) {
            setMessage("Nothing here. Please input your API discovery endpoint");
            return;
        }

        try {
            setProcessing(true);

            const result = await fetch(mount);
            const json = await result.json();

            if (!json?.permissions?.length) {
                throw new Error("Invalid data format");
            }

            const allPermissions = (json.permissions as ApiInfo[]).sort((p1, p2) => {
                return p1.mount?.localeCompare(p2.mount);
            });
            setPermissions(allPermissions);

            setMessage("");
        } catch (err: any) {
            setMessage(`Error: ${err.message || "Cannot fetch API"}`);
        } finally {
            setProcessing(false);
        }
    };

    const hideDetail = () => {
        navigateAPI({
            apiUrl,
            id: "",
        });
    };

    useEffect(() => {
        getApiInfo(apiUrl);
    }, [apiUrl]);

    useEffect(() => {
        const apiInfo = getApiObject();
        setApiUrl(apiInfo?.apiUrl || "");
        setId(apiInfo?.id || "");
    });

    const groups = (permissions || [])
        .map((p) => p.apiGroup || "")
        .reduce((groups, g) => (groups.includes(g) ? groups : groups.concat(g)), [] as string[]);

    const filteredPermissions = !filter
        ? permissions || []
        : (permissions || []).filter((p) => [p.name, p.apiGroup, p.id].some((p) => p?.includes(filter)));

    const selectedPermissions = id && permissions && permissions.find((p) => p.id === id);

    return (
        <Wrapper>
            <Affix className="fix-top" offsetTop={0.01}>
                <div className="page-header">
                    {!permissions ? (
                        <Input.Search
                            defaultValue={apiUrl}
                            disabled={processing}
                            className="url-input"
                            allowClear
                            placeholder="Your API discovery endpoint here"
                            enterButton={<SendOutlined />}
                            onSearch={search}
                        />
                    ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span className="api-url">{apiUrl}</span>
                            <Button icon={<EditFilled />} type="primary" onClick={() => setPermissions(undefined)} />
                        </div>
                    )}
                    <Input
                        className="search-input"
                        onChange={(e) => debouncedFilter(e.target.value)}
                        disabled={!!message || processing}
                        allowClear
                        placeholder="Search for endpoint..."
                        suffix={<SearchOutlined />}
                    />
                </div>
            </Affix>
            <div className="main-content">
                {message && <div className="message">{processing ? "Fetching API info..." : message}</div>}
                {permissions && (
                    <div className="permissions">
                        {filter && filteredPermissions.length < permissions.length && (
                            <div className="filter">{`${filteredPermissions.length}/${permissions.length} filtered`}</div>
                        )}
                        {groups && (
                            <div className="permission-group">
                                {groups.map((g) => {
                                    const groupPermissions = filteredPermissions?.filter((p) => !g || p.apiGroup === g);
                                    return (
                                        <div key={g}>
                                            {!!groupPermissions.length && (
                                                <>
                                                    <Divider>{g || "UNKNOWN"}</Divider>
                                                    {groupPermissions.map((p) => (
                                                        <PermissionItem
                                                            apiUrl={apiUrl}
                                                            key={p.id}
                                                            p={p}
                                                            canNavigate={true}
                                                        />
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {selectedPermissions && (
                //@ts-ignore
                <Modal
                    onCancel={hideDetail}
                    onOk={hideDetail}
                    closable={false}
                    width={987}
                    title={"Api detail"}
                    visible={true}
                    cancelButtonProps={{ style: { visibility: "hidden" } }}>
                    <PermissionItem apiUrl={apiUrl} p={selectedPermissions} />
                </Modal>
            )}
        </Wrapper>
    );
}
