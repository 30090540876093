import { AbstractLogger, AccessToken } from "@clairejs/core";
import axios from "axios";

import { AbstractHttpClient } from "./AbstractHttpClient";
import { AbstractTokenManager } from "./AbstractTokenManager";

export class AxiosApiClient extends AbstractHttpClient {
    public constructor(readonly apiServerUrl: string, readonly logger: AbstractLogger, readonly tokenManager: AbstractTokenManager) {
        super(apiServerUrl, logger, tokenManager);
    }

    protected getRefreshedAccessToken(): Promise<AccessToken> {
        throw "Refresh token not supported in default implementation";
    }

    public async get<R>(url: string, headers?: any): Promise<R> {
        const finalUrl = this.resolveUrl(url);
        this.logger.debug("[GET] ", finalUrl);
        return this.resolveResult(async () => axios.get(finalUrl, await this.resolveHeaders(headers)));
    }
    public async post<T, R>(url: string, body: T, headers?: any): Promise<R> {
        const finalUrl = this.resolveUrl(url);
        this.logger.debug("[POST] ", finalUrl);
        return this.resolveResult(async () => axios.post(this.resolveUrl(url), body, await this.resolveHeaders(headers)));
    }
    public async put<T, R>(url: string, body: T, headers?: any): Promise<R> {
        const finalUrl = this.resolveUrl(url);
        this.logger.debug("[PUT] ", finalUrl);
        return this.resolveResult(async () => axios.put(this.resolveUrl(url), body, await this.resolveHeaders(headers)));
    }

    public async delete<R>(url: string, headers?: any): Promise<R> {
        const finalUrl = this.resolveUrl(url);
        this.logger.debug("[DEL] ", finalUrl);
        return this.resolveResult(async () => axios.delete(this.resolveUrl(url), await this.resolveHeaders(headers)));
    }
}
