import { DtoFieldMetadata, DtoMetadata } from "@clairejs/core";
import { Button, Table, Tooltip } from "antd";
import DtoFieldJson from "./DtoFieldJson";

interface Props {
    dto: DtoMetadata;
}

export default function ParamTable({ dto }: Props) {
    const columns = [
        {
            title: "Name",
            width: 144,
            render: (_text: any, record: DtoFieldMetadata) => (
                <div>
                    <Tooltip placement="right" trigger={["click"]} title={record.description}>
                        <Button type="link" size="small">
                            <span style={{ fontFamily: "monospace" }}>{`${record.name}`}</span>
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: "Info",
            render: (_text: any, record: DtoFieldMetadata) => {
                return (
                    <div>
                        <DtoFieldJson dto={{ ...record, name: "" }} />
                    </div>
                );
            },
        },
    ];

    return dto.primitiveType ? (
        <div>{dto.primitiveType}</div>
    ) : (
        <Table bordered rowKey={(record) => record.name} pagination={false} columns={columns} dataSource={dto.fields} />
    );
}
