import { CopyFilled } from "@ant-design/icons";
import { DataType, DtoFieldMetadata, DtoMetadata } from "@clairejs/core";
import { Button, Table, Tooltip } from "antd";
import { copyToClipBoard } from "../utils";
import DtoFieldJson from "./DtoFieldJson";

interface Props {
    dto: DtoMetadata;
}

const serialize = (dto?: DtoFieldMetadata) => {
    let value = "";

    if (!dto) {
        return value;
    }

    if (dto.dataType === DataType.STRING) {
        value = `""`;
    } else if (dto.dataType === DataType.NUMBER) {
        value = `0`;
    } else if (dto.dataType === DataType.BOOLEAN) {
        value = `false`;
    } else if (dto.vectorProps) {
        if (dto.enum || dto.vectorProps.superSet) {
            value = `[${(dto.enum || dto.vectorProps.superSet || []).map((v) => JSON.stringify(v)).join(",")}]`;
        } else {
            if (dto.vectorProps.elementDataType === DataType.STRING) {
                value = `[""]`;
            } else if (dto.vectorProps.elementDataType === DataType.NUMBER) {
                value = `[0]`;
            } else if (dto.vectorProps.elementDataType === DataType.BOOLEAN) {
                value = `[false]`;
            } else {
                value = `[${serialize({
                    name: "",
                    description: "",
                    dataType: DataType.OBJECT,
                    elementDto: dto.elementDto,
                })}]`;
            }
        }
    } else if (dto.elementDto) {
        value = dto.elementDto.fields.map((f) => `"${f.name}":${serialize(f)}`).join(",");
        value = `{${value}}`;
    }

    return value;
};

export default function QueryTable({ dto }: Props) {
    const copyQuery = (record: DtoFieldMetadata) => {
        const value = serialize(record);
        const data = `&${record.name}=${value}`;
        copyToClipBoard(data);
    };

    const columns = [
        {
            title: "Name",
            width: 144,
            render: (_text: any, record: DtoFieldMetadata) => (
                <div>
                    <Tooltip placement="right" trigger={["click"]} title={record.description}>
                        <Button type="link" size="small">
                            <span style={{ fontFamily: "monospace" }}>{`&${record.name}=`}</span>
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: "Info",
            render: (_text: any, record: DtoFieldMetadata) => {
                return (
                    <div>
                        <DtoFieldJson dto={{ ...record, name: "" }} />
                    </div>
                );
            },
        },
        {
            align: "center" as any,
            width: 144,
            render: (_text: any, record: DtoFieldMetadata) => {
                return (
                    <Button size="small" icon={<CopyFilled />} onClick={() => copyQuery(record)}>
                        Copy
                    </Button>
                );
            },
        },
    ];

    return dto.primitiveType ? (
        <div>{dto.primitiveType}</div>
    ) : (
        <Table bordered rowKey={(record) => record.name} pagination={false} columns={columns} dataSource={dto.fields} />
    );
}
