import {
    AbstractModel,
    Constructor,
    CreateManyRequestBody,
    CreateManyResponseBody,
    GetManyQueries,
    GetManyResponseBody,
    UpdateManyBody,
    UpdateManyQueries,
    UpdateManyResponse,
} from "@clairejs/core";
import { AbstractHttpClient } from "./AbstractHttpClient";

export const stringifyQueries = (queries: Record<string, any>) => {
    const keys = Object.keys(queries);
    return keys.reduce((collector, key) => collector + `${key}=${JSON.stringify(queries[key])}&`, "");
};

export const removeInstances = <T extends AbstractModel>(target: T[], source: T[]) => {
    const result = target.slice();
    for (const instance of source) {
        const index = result.indexOf(instance);
        if (index >= 0) {
            result.splice(index, 1);
        }
    }
    return result;
};

export const mergeInstances = <T extends AbstractModel>(model: Constructor<T>, target: T[], source: Partial<T>[]) => {
    const result = target.slice();
    for (const instance of source) {
        const index = result.findIndex((i) => i.id === instance.id);
        if (index < 0) {
            result.push(Object.assign(new model(), instance));
        } else {
            Object.assign(result[index], instance);
        }
    }
    return result;
};

export class CrudApi<T extends AbstractModel> {
    constructor(readonly model: Constructor<T>, readonly httpClient: AbstractHttpClient) {}

    protected getEndpointBaseUrl() {
        return `/${this.model.name.toLowerCase()}`;
    }

    async getMany(queries?: GetManyQueries<T>): Promise<GetManyResponseBody<T>> {
        return await this.httpClient.get(`${this.getEndpointBaseUrl()}?${stringifyQueries(queries || {})}`);
    }

    async updateMany(body: UpdateManyBody<T>, queries?: UpdateManyQueries<T>): Promise<UpdateManyResponse<T>> {
        return await this.httpClient.put(`${this.getEndpointBaseUrl()}?${stringifyQueries(queries || {})}`, body);
    }

    async deleteMany(queries?: UpdateManyQueries<T>): Promise<UpdateManyResponse<T>> {
        return await this.httpClient.delete(`${this.getEndpointBaseUrl()}?${stringifyQueries(queries || {})}`);
    }

    async createMany(body: CreateManyRequestBody<T>): Promise<CreateManyResponseBody<T>> {
        return await this.httpClient.post(`${this.getEndpointBaseUrl()}`, body);
    }
}
