import { blue, gold, green, grey, red } from "@ant-design/colors";
import { LinkOutlined } from "@ant-design/icons";
import { DataType, DtoMetadata, HttpMethod, ApiInfo } from "@clairejs/core";
import { Button, Card, Popover, Tabs, Tag } from "antd";
import { memo, useState } from "react";

import { useApiNavigator } from "../hooks";
import { copyToClipBoard } from "../utils";
import DtoFieldJson from "./DtoFieldJson";
import ParamTable from "./ParamTable";
import QueryTable from "./QueryTable";

const dtoFields: { label: string; field: keyof ApiInfo; isResponse?: boolean }[] = [
    { label: "Params", field: "paramDto" },
    { label: "Queries", field: "queryDto" },
    { label: "Body", field: "bodyDto" },
    { label: "Response", field: "responseDto", isResponse: true },
];

const filterServerValue = (dto: DtoMetadata): DtoMetadata => {
    return {
        ...dto,
        fields: dto.fields
            .filter((f) => !f.serverValue)
            .map((f) => (f.elementDto ? { ...f, elementDto: filterServerValue(f.elementDto) } : f)),
    };
};

interface Props {
    apiUrl: string;
    p: ApiInfo;
    canNavigate?: boolean;
}
const PermissionItem = ({ apiUrl, p, canNavigate }: Props) => {
    const { navigateAPI, getApiQuery } = useApiNavigator();
    const [copied, setCopied] = useState(false);

    const showDetail = () => {
        navigateAPI({ apiUrl, id: p.id });
    };

    const onCopy = () => {
        copyToClipBoard(getLink());
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const getLink = () => {
        return (
            window.location.origin +
            window.location.pathname +
            `${getApiQuery({
                id: p.id,
                apiUrl,
            })}`
        );
    };

    return (
        <Card
            key={p.id}
            className="permission-item"
            title={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tag
                        style={{
                            minWidth: "55pt",
                            textAlign: "center",
                            color: "#ffffff",
                            marginLeft: "5pt",
                            background:
                                p.method === HttpMethod.GET
                                    ? green[5]
                                    : p.method === HttpMethod.POST
                                    ? blue[5]
                                    : p.method === HttpMethod.DEL
                                    ? red[5]
                                    : p.method === HttpMethod.PUT
                                    ? gold[5]
                                    : grey[5],
                        }}>
                        {p.method?.toUpperCase()}
                    </Tag>
                    <span style={{ fontFamily: "monospace", marginLeft: "8pt" }}>{p.mount}</span>
                </div>
            }
            extra={
                <div>
                    {canNavigate ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showDetail();
                            }}>
                            {p.name}
                        </Button>
                    ) : (
                        <span>{p.name}</span>
                    )}
                    <Popover content="Copied!" visible={copied}>
                        <Button onClick={onCopy} style={{ marginLeft: "13pt" }} size="small" icon={<LinkOutlined />} />
                    </Popover>
                </div>
            }>
            {p.description && <div className="api-description">{p.description}</div>}
            <Tabs>
                {dtoFields
                    .filter((f) => !!p[f.field])
                    .map((f) => {
                        const originalDto = p[f.field] as DtoMetadata;
                        const dto = !f.isResponse ? filterServerValue(originalDto) : originalDto;

                        return (
                            <Tabs.TabPane key={f.field} tab={f.label} disabled={!p[f.field]}>
                                {["bodyDto", "responseDto"].includes(f.field) ? (
                                    <DtoFieldJson
                                        showCopy={true}
                                        dto={{
                                            name: "",
                                            description: "",
                                            dataType: dto.primitiveType || DataType.OBJECT,
                                            elementDto: dto,
                                        }}
                                    />
                                ) : ["queryDto"].includes(f.field) ? (
                                    <QueryTable dto={dto} />
                                ) : (
                                    <ParamTable dto={dto} />
                                )}
                            </Tabs.TabPane>
                        );
                    })}
            </Tabs>
        </Card>
    );
};

export default memo(PermissionItem);
