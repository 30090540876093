import { AbstractLogger, AccessToken } from "@clairejs/core";
import { AbstractTokenManager } from "./AbstractTokenManager";

export abstract class AbstractHttpClient {
    private refreshing = false;
    private refreshQueue: {
        resolver: () => void;
    }[] = [];

    constructor(readonly apiServerUrl: string, readonly logger: AbstractLogger, readonly tokenManager: AbstractTokenManager) {}

    protected abstract getRefreshedAccessToken(): Promise<AccessToken>;

    protected resolveUrl(url: string): string {
        return this.apiServerUrl + url;
    }

    protected async resolveHeaders(headers: object): Promise<any> {
        const accessToken = await this.tokenManager.getAccessToken();
        return { headers: { Authorization: accessToken?.token || "", ...headers } };
    }

    protected async resolveResult(apiCall: () => Promise<any>): Promise<any> {
        try {
            return (await apiCall()).data;
        } catch (err) {
            //-- first attempt failed, check if that was because session was expired
            if (err.response?.code !== 401) {
                throw err;
            }
            await this.refreshToken(err);
            return (await apiCall()).data;
        }
    }

    async refreshToken(originalErr: any) {
        if (this.refreshing) {
            await new Promise<void>((resolve) => {
                this.refreshQueue.push({ resolver: resolve });
            });
        } else {
            //-- call to api server to refresh token
            const oldToken = await this.tokenManager.getAccessToken();
            if (!oldToken || !oldToken.refreshToken) {
                //-- there is no refresh token to refresh
                throw originalErr;
            }
            this.refreshing = true;
            try {
                const newToken = await this.getRefreshedAccessToken();
                await this.tokenManager.setAccessToken(newToken);
                this.logger.debug("Access token was refreshed");
                this.refreshing = false;

                for (const refresh of this.refreshQueue) {
                    refresh.resolver();
                }
            } catch (_e) {
                this.logger.debug(_e);
                throw originalErr;
            }
        }
    }

    abstract get<T>(url: string, headers?: any): Promise<T>;
    abstract post<T extends any, R extends any>(url: string, body: R, headers?: any): Promise<T>;
    abstract put<T extends any, R extends any>(url: string, body: R, headers?: any): Promise<T>;
    abstract delete<T>(url: string, headers?: any): Promise<T>;
}
